// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-galleri-djur-och-vaxter-js": () => import("./../../../src/pages/galleri/djur-och-vaxter.js" /* webpackChunkName: "component---src-pages-galleri-djur-och-vaxter-js" */),
  "component---src-pages-galleri-exempel-pa-arbeten-js": () => import("./../../../src/pages/galleri/exempel-pa-arbeten.js" /* webpackChunkName: "component---src-pages-galleri-exempel-pa-arbeten-js" */),
  "component---src-pages-galleri-natur-och-kulturmiljoer-js": () => import("./../../../src/pages/galleri/natur-och-kulturmiljoer.js" /* webpackChunkName: "component---src-pages-galleri-natur-och-kulturmiljoer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

